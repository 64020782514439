import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { CONTACT } from '@constants/routes'
// import CouponStrip from '@components/Layout/couponStrip'

//
export default function Layout({ children, location }) {
    const { pathname } = location

    useEffect(() => {
        const isDark = !('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches

        if (localStorage.theme === 'dark' || isDark) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }

        setTimeout(() => {
            window.scroll({ top: -1, left: 0, behavior: 'smooth' })
        }, 10)
    }, [location])

    return (
        <>
            {/*  {!pathname.includes('signup') && !pathname.includes('thanks') && <CouponStrip />}  */}
            <>{children}</>
            {!pathname.includes('signup') && !pathname.includes('thanks') && !pathname.includes('contact') && (
                <Link
                    to={CONTACT}
                    className='fixed bottom-5 right-5 z-50 hidden rounded-full rounded-br-none bg-haze ring-2 ring-white xl:block'
                >
                    <span className='absolute right-0.5 top-0.5 inline-flex size-3 rounded-full bg-eastern' />
                    <span className='absolute right-0.5 top-0.5 inline-flex size-3 animate-ping rounded-full bg-eastern opacity-75' />
                    <EnvelopeIcon className='m-3 size-7 stroke-white p-0.5' />
                </Link>
            )}
        </>
    )
}
